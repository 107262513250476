import Vue from "vue";
import { createElement } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { getApiErrors } from "@/helpers/apiHelper";
export default Vue.extend({
    data() {
        return {
            flValInput: null,
            dropDownInstance: null
        };
    },
    computed: {
        textBoxFilter() {
            return {
                ui: {
                    create: (args) => {
                        this.flValInput = createElement("input", { className: "e-input" });
                        args.target.appendChild(this.flValInput);
                    },
                    write: (args) => {
                        this.flValInput.value =
                            args.filteredValue == undefined ? "" : args.filteredValue;
                    },
                    read: (args) => {
                        args.fltrObj.filterByColumn(args.column.field, args.operator, args.element.value);
                    }
                }
            };
        },
        yesNoDropDownFilter() {
            return {
                ui: {
                    create: (args) => {
                        const yesNoData = [
                            { text: this.$t("shared.yes"), value: true },
                            { text: this.$t("shared.no"), value: false }
                        ];
                        const yesNoFields = { text: "text", value: "value" };
                        this.flValInput = createElement("input", { className: "e-input" });
                        args.target.appendChild(this.flValInput);
                        this.dropDownInstance = new DropDownList({
                            dataSource: yesNoData,
                            fields: yesNoFields,
                            placeholder: this.$t("components.dropDown.selectValue"),
                            popupHeight: "200px"
                        });
                        this.dropDownInstance.appendTo(this.flValInput);
                    },
                    write: (args) => {
                        this.dropDownInstance.value =
                            args.filteredValue == undefined ? "" : args.filteredValue;
                    },
                    read: (args) => {
                        args.fltrObj.filterByColumn(args.column.field, args.operator, this.dropDownInstance.value);
                    }
                }
            };
        }
    },
    methods: {
        yesNoGridValueMapper(field, data, column) {
            if (data[field]) {
                return this.$t("shared.yes");
            }
            else {
                return this.$t("shared.no");
            }
        },
        actionFailure(args) {
            let error;
            if (Array.isArray(args.error)) {
                const errors = getApiErrors(args.error[0].error);
                error = errors.length > 0 ? errors[0].message : "Unknown";
            }
            else
                error = args.error.error.responseText;
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: error
            }));
        }
    }
});
