import Vue from "vue";
import { GridPlugin, Page, Sort, Toolbar, Edit, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import gridMixin from "@/mixins/syncfusion/grid";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [SpinnerMixin, gridMixin, TitleMixin],
    data() {
        return {
            employee: {},
            title: this.$t("employeeWorkHours.workHours"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL +
                    `employees/${this.$route.params.employeeId != undefined ? this.$route.params.employeeId : this.$store.getters["account/getEmployeeId"]}/work-weeks/grid-data`,
                removeUrl: process.env.VUE_APP_API_BASE_URL +
                    `employees/${this.$route.params.employeeId != undefined ? this.$route.params.employeeId : this.$store.getters["account/getEmployeeId"]}/work-weeks/grid-delete`,
                adaptor: new CustomUrlAdaptor()
            }),
            filterOptions: { type: "Menu" },
            sortOptions: {
                columns: [
                    { field: "OfficeId", direction: "Ascending" },
                    { field: "StartDateTime", direction: "Descending" }
                ]
            },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            searchOptions: {
                fields: ["Name", "Description"]
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ]
        };
    },
    provide: {
        grid: [Page, Sort, Toolbar, Edit, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getEmployeeId: "account/getEmployeeId"
        }),
        employeeId() {
            if (this.$route.params.employeeId != undefined)
                return this.$route.params.employeeId;
            return this.getEmployeeId;
        }
    },
    methods: {
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                if (this.$route.params.employeeId != undefined)
                    return this.$router.push(`/employees/${this.employeeId}/work-weeks/create`);
                return this.$router.push('/work-weeks/create');
            }
        },
        commandClick(args) {
            if (args.commandColumn.type === "Custom_Edit") {
                if (this.$route.params.employeeId != undefined)
                    return this.$router.push(`/employees/${this.employeeId}/work-weeks/${args.rowData.Id}/edit`);
                return this.$router.push(`/work-weeks/${args.rowData.Id}/edit`);
            }
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("employeeWorkHours.delete.toastSuccessTitle"), this.$t("employeeWorkHours.delete.toastSuccessContent", {
                    name: args.data[0].Name
                }));
            }
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.employee = await this.$store.dispatch("employee/get", {
                id: this.employeeId
            });
            this.setTitle(`${this.title} - ${this.employee.name} ${this.employee.surname}`);
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/employees");
        }
    }
});
